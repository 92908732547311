
// External
import { defineComponent } from "vue";

// Utils, Types, ...
import { usePublicStore } from "@/store/index";
import router from "@/router";

// Components

export default defineComponent({
  setup() {
    // ---------------
    // Vars

    //* Store
    const store = usePublicStore();

    // ---------------
    // Process

    return { store };
  },
  watch: {
    $route: {
      handler: function (to: any): void {
        // ---------------
        // Vars

        // CheckRoute (Function)
        const checkRoute = function(connection: boolean, name: string) {
          if (connection && to.name !== name) router.push({ name });
        }
        // ---------------
        // Process

        // Case 1: Without authorization
        checkRoute(!this.store.$state.public.connected, 'login');

        // Case 2: With authorization
        checkRoute(this.store.$state.public.connected, 'home');
      },
      deep: true,
    },
  },
});
