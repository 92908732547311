/** ----------------------------------------------------------------
* @Copyright    : Ux-key SAS
*
* @Author       : Luc BRUNDARD
* @Contributors : Julieta PINON
*
* @File         : index.ts
* @Description  : Define the application store
* @Created_at   : 01/08/2022
* ----------------------------------------------------------------
*/

// External
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

// Utils, Types, ...
import { NodeClass } from '../common/interfaces/treeNode.interface';
import { initialPageSize } from '@/common/constants/values.constant';

// ---------------
// Vars

//* UsePublicStore
export const usePublicStore = defineStore('public', {
  state: () => ({
    public: useStorage('public', {
      connected: false as boolean,
      errorMessage: '' as string | undefined,
      minioTree: {} as Record<string, NodeClass[]>,
      filteredTree: undefined as NodeClass[] | undefined,
      pageSize: initialPageSize,
      seeMore: {} as Record<string, boolean>,
      treeFolders: {} as Record<string, Record<string, number>>,
      totalMinioRows: 0,
    })
  }),
  getters: {},
  actions: {}
});
