/** ----------------------------------------------------------------
* @Copyright    : Ux-key SAS
*
* @Author       : Luc BRUNDARD
* @Contributors : Julieta PINON
*
* @File         : index.ts
* @Description  : Define application routes
* @Created_at   : 01/07/2022
* ----------------------------------------------------------------
*/

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// ---------------
// Vars

//* Routes
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
]

//* Router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
