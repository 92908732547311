/** ----------------------------------------------------------------
* @Copyright    : Ux-key SAS
*
* @Author       : Julieta PINON
*
* @File         : values.constant.ts
* @Description  : Values constant
* @Created_at   : 20/01/2023
* ----------------------------------------------------------------
*/

// ---------------
// Vars

//* Folder main path
export const folderMainPath = "chunks/";

//* Page size
export const initialPageSize = 10000;
