/** ----------------------------------------------------------------
* @Copyright    : Ux-key SAS
*
* @Author       : Julieta PINON
*
* @File         : i18n.ts
* @Description  : i18n configuration
* @Created_at   : 01/12/2022
* ----------------------------------------------------------------
*/

import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

// --------------------------------
// VARS

//* i18n
let i18n: any;

//* Support locales
export const SUPPORT_LOCALES = {
  fr: {
    isoCode: 'fr',
    label: 'Français',
  },
  en: {
    isoCode: 'en',
    label: 'English',
  },
  es: {
    isoCode: 'es',
    label: 'Español',
  }
};

// --------------------------------
// SETTERS

/**
 * Set I18n language in localStorage
 *
 * @param locale
 */
export function setI18nLanguage(locale: string) {
  // ---------------
  // Prepare

  setLoadLocaleMessages(locale);

  // Check: Mode
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  // ---------------
  // Process

  document.querySelector('html')?.setAttribute('lang', locale);
  localStorage.setItem('lang', locale);
}

/**
 * Set locale and load messages
 *
 * @param locale
 * @returns
 */
export async function setLoadLocaleMessages(locale: string) {
  // ---------------
  // Vars

  //* Messages
  // load locale messages with dynamic import
  const messages = await import(`../../locales/${locale}.json`);

  // ---------------
  // Prepare

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  // ---------------
  // Process

  return nextTick();
}

/**
 * Create and set config i18n
 *
 * @returns
 */
export default function setupI18n() {
  // Check: Is i18n
  if (!i18n) {
    // ---------------
    // Vars

    const locale = getLocale();

    //* Locale files
    const en = require('../../locales/en.json');
    const es = require('../../locales/es.json');
    const fr = require('../../locales/fr.json');

    // ---------------
    // Prepare

    i18n = createI18n({
      legacy: false,
      globalInjection: true,
      locale,
      messages: {
        fr,
        es,
        en
      }
    })

    // ---------------
    // Process

    setI18nLanguage(locale);
  }
  return i18n;
}

function getLocale() {
  // ---------------
  // Vars

  //* Locale
  const locale = localStorage.getItem('lang') || navigator.language || 'fr';

  // ---------------
  // Process

  return Object.keys(SUPPORT_LOCALES).filter(k => locale.includes(k))[0];
}
