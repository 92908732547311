/** ----------------------------------------------------------------
* @Copyright    : Ux-key SAS
*
* @Author       : Luc BRUNDARD
* @Contributors : Julieta PINON
*
* @File         : main.ts
* @Description  : Define plugins
* @Created_at   : 01/08/2022
* ----------------------------------------------------------------
*/

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo, faBackwardFast, faBackward, faForward, faForwardFast, faEye, faEyeSlash, faXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import App from './App.vue';
import router from './router';
import i18n from './modules/i18n';

axios.defaults.withCredentials = true;

library.add(faCircleInfo, faBackwardFast, faBackward, faForward, faForwardFast, faEye, faEyeSlash, faXmark, faMagnifyingGlass);

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(i18n());
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.mount('#app');
